import type {
  FloatPossibleValue,
  Form as FormSchema,
  IntPossibleValue,
  SpecialChecksValidation,
  StringPossibleValue
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { I18n } from '@aurora/shared-types/texts';
import type React from 'react';
import type { Col, ColProps, FormLabelProps, Row as BootstrapRow, RowProps } from 'react-bootstrap';
import type { ClassNamesFnWrapper } from 'react-bootstrap/lib/esm/createClassNames';
import type { UseControllerReturn } from 'react-hook-form';
import type { FieldValues, FormState, Mode, UseFormReturn } from 'react-hook-form/dist/types';
import type { FieldPath, FieldPathValue } from 'react-hook-form/dist/types/path/';
import type { RegisterOptions, Validate } from 'react-hook-form/dist/types/validator';
import type { ComponentProp } from '../../helpers/components/CustomComponentsHelper';
import type { ElementFocusHandler } from '../../helpers/ui/ComponentHelper/ComponentHelper';
import type IconTypes from '../../public/static/graphics/processed/enums';
import type { LoadingButtonVariant } from '../common/Button/enums';
import type { LoadingButtonProps } from '../common/Button/types';
import type InfoPopoverProps from '../common/InfoPopover/InfoPopoverProps';
import type {
  FormActionButtonBarPosition,
  FormActionButtonsPosition,
  FormFieldVariant,
  FormGroupAsElement,
  FormGroupFieldSeparator,
  FormGroupFieldType,
  ToggleableState
} from './enums';

/**
 * Forms Section
 */

/**
 * Renders the field of the form when using the Layout json file.
 */
export interface LayoutFormField extends FormFieldRef {
  /**
   * Specification for Form.Group bootstrap component for the field.
   */
  formGroup?: FormGroup;

  /**
   * Adds specified classname to the field.
   */
  className?: string;
}

/**
 * Renders the Form.Group bootstrap component. The component can be rendered as a bootstrap Row or Col component.
 * If rendered as a Row, all props specified at {@link https://react-bootstrap.github.io/layout/grid/#row-props} can be
 * used. When rendered as a Row, label can and should be rendered as a Col bootstrap component using the labelSpec
 * property by setting column to true and the field control can and should be wrapped with a column using the controlCol
 * property so that the UI renders properly. When rendered as a Col, all props specified at
 * {@link https://react-bootstrap.github.io/layout/grid/#col-props} can be used. The className property specified will
 * apply to the field group.
 *
 * TODO:: revisit definition
 */
export interface FormGroup<
  AsT extends FormGroupAsElement | React.ElementType = 'div',
  FormLabelAsT extends React.ElementType = 'label'
> {
  /**
   * Element to render the form field group as. Can be Row, Col or undefined.
   */
  as?: AsT extends FormGroupAsElement.ROW
    ? typeof BootstrapRow
    : AsT extends FormGroupAsElement.COL
    ? typeof Col
    : AsT;

  /**
   * When rendered as Row, bootstrap RowProps can be specified. When rendered as Col, bootstrap ColProps can be specified.
   */
  props?: AsT extends FormGroupAsElement.ROW
    ? RowProps
    : AsT extends FormGroupAsElement.COL
    ? ColProps
    : AsT extends React.ElementType
    ? React.ComponentPropsWithRef<AsT>
    : never;

  /**
   * Specification to render the label. Can be rendered as Col when Form.Group is rendered as row. When rendered as Col,
   * all props specified at {@link https://react-bootstrap.github.io/layout/grid/#col-props} can be used.
   */
  label?:
    | false
    | (AsT extends FormGroupAsElement.ROW
        ? FormLabel<FormLabelAsT> & {
            props: AsT extends React.ElementType
              ? FormLabelProps & React.ComponentPropsWithRef<AsT> & { column: true }
              : FormLabelProps & { column: true };
          }
        : AsT extends FormGroupAsElement.COL
        ? FormLabel<FormLabelAsT> & {
            props: FormLabelProps & React.ComponentPropsWithRef<AsT> & { column: never };
          }
        : FormLabel<FormLabelAsT>);

  /**
   * Whether to include the description text for the input. Use a string to override the text value.
   */
  description?: true | string | React.ReactElement;

  /**
   * Classes applied to the description.
   */
  descriptionClassName?: string;

  /**
   * Whether to include the info text popover. Use a component to override the popover.
   */
  showInfo?: boolean | React.ReactElement;

  /**
   * Specification to wrap the form field control with a Col. Only applicable when Form.Group is rendered as Row.
   */
  controlCol?: AsT extends FormGroupAsElement.ROW
    ? ColProps & {
        /**
         * Element type to display the control as.
         */
        as?: React.ElementType;
      }
    : never;

  /**
   * Adds specified classname to the Form.Group component.
   */
  className?: string;
}

/**
 * Represents a field watcher for a form. When a watched field is changed, the
 * callback will be called.
 */
export interface FieldWatcher<FormDataT extends FieldValues, ResponseT = void> {
  /**
   * The fields to watch.
   */
  watchFields: Array<FieldPath<FormDataT>>;
  /**
   * The callback when a watched field changes. This will only include the
   * watched fields. Values from other fields can be retrieved using the form
   * methods.The callback is also called during form render with the initial
   * set of default values
   *
   * @callback
   * @param formData - the updated form data
   * @param methods - the form methods from react-hook-form.
   */
  callback: (formData: Partial<FormDataT>, methods: UseFormReturn<FormDataT>) => ResponseT;
}

/**
 * Represents a field watcher to decide whether a field is visible or not based on the callback.
 */
export interface IsVisibleFieldWatcher<FormDataT extends FieldValues, ResponseT = void> {
  /**
   * The fields to watch.
   */
  watchFields: WatchFields<FormDataT>;
  /**
   * The callback when a watched field changes. This will only include the
   * watched fields.
   *
   * @callback
   * @param formData - the updated form data
   */
  callback: (formData: Readonly<Partial<FormDataT>>) => ResponseT;
}
export type WatchFields<FormDataT> = Array<FieldPath<FormDataT>> | 'all';

export type AllValidationRules<
  FormDataT extends FieldValues,
  FieldPathT extends FieldPath<FormDataT>
> = Pick<
  RegisterOptions<FormDataT, FieldPathT>,
  'required' | 'min' | 'max' | 'maxLength' | 'minLength' | 'pattern' | 'validate'
>;

export type FieldValidationRules<
  FormDataT extends FieldValues,
  TFieldPath extends FieldPath<FormDataT>
> = Omit<AllValidationRules<FormDataT, TFieldPath>, 'validate'> & {
  validate?: Record<string, Validate<FieldPathValue<FormDataT, TFieldPath>>>;
};

export type PossibleValueTypes = FloatPossibleValue | StringPossibleValue | IntPossibleValue;

/**
 * Type of form field
 */
export enum FieldType {
  /**
   * Non-native HTML form field where react manages the state for the form. Uses Controller for the field.
   */
  CONTROLLED = 'controlled',
  /**
   * Native HTMl form fields or form fields where react does not manage the state. Uses register to register the field.
   */
  UNCONTROLLED = 'uncontrolled'
}

/**
 * Form field specification to render the field. NameT is the field name and DataT is the definition of the bean that
 * holds all the fields of the form.
 * InternalT allows defaultValue to be of a different type than the field stores internally -- if you supply it,
 * you should also implement 'transform'
 */
export interface FormFieldType<
  NameT extends FieldPath<FormDataT>,
  FormDataT extends FieldValues,
  VariantT extends FormFieldVariant,
  ValueT = FieldPathValue<FormDataT, NameT>
> {
  /**
   * Form field variant.
   */
  fieldVariant: VariantT;
  /**
   * Name to use for the input field.
   */
  name: NameT;

  /**
   * Validations for the form field. Form submit will fail if validation fails.
   */
  validations?: FieldValidationRules<FormDataT, NameT>;

  /**
   * Additional supported HTML attributes for the element.
   */
  attributes?: Record<string, string | boolean>;

  /**
   * Form field form group specification to specify how react bootstrap Form.Group should render.
   */
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>;

  /**
   * Initial value of the field when the form first gets rendered.
   */
  defaultValue: ValueT;

  /**
   * Callback method that determines whether the field is visible or not.
   * If false, then the field value is not included when the form is submitted.
   */
  isVisible?: IsVisibleFieldWatcher<FormDataT, boolean>;

  /**
   * Classes applied to the element
   */
  className?: string;

  /**
   * Classes applied to the input field
   */
  dateInputClassName?: string;

  /**
   * Classes applied to the error element.
   */
  errorClassName?: string;

  /**
   * Specifies focus to be set on a particular field on page load.
   */
  focus?: boolean;
  /**
   * Allows for a custom focus handler called when the field wants to be
   * focused on initial render of the form.
   */
  focusHandler?: ElementFocusHandler;
  /**
   * Whether the form field is disabled or not.
   */
  disabled?: boolean;

  /**
   * Class name(s) to apply to the tooltip icon
   */
  popoverClassName?: string;

  /**
   * A component to use for the popover. Defaults to InfoPopover
   */
  popoverComponent?: React.FC<React.PropsWithChildren<InfoPopoverProps>>;

  /** Special validations, these are not included in the `validations` property as they are not handled by react-hook-form */
  specialChecks?: SpecialChecksValidation[];

  /**
   * Whether to show the info text popover.
   */
  showInfo?: boolean;

  /**
   * Whether if the select is clearable or not.
   */
  isClearable?: boolean;

  /**
   * Minimum input length to start the search.
   */
  minimumInputLengthForSearch?: number;

  /**
   * Whether this is a custom field.
   */
  custom?: boolean;
}

/**
 * Fieldset properties that are allowed for fieldset html tags.
 */
export interface FieldsetProps {
  /**
   * Whether the fieldset is disabled or not.
   */
  disabled?: boolean;

  /**
   * The form for which the fieldset is rendered.
   */
  form?: string;

  /**
   * The inner class name for the fieldset.
   */
  className?: string;
}

export interface PossibleValue<ValueT, DataT extends FieldValues> {
  /**
   * Key that uniquely identifies the possible value. This is the pointer to the value for the field and will be
   * used in form field during render.
   */
  key: string;

  /**
   * Possible value. Maps 1-1 with key when specified.
   */
  value: ValueT;

  /**
   * Label override for the possible value.
   */
  label?: string;

  /**
   * Description of the possible value.
   */
  description?: true | string;

  /**
   * Reference to Icon image for the option.
   */
  icon?: IconTypes;

  /**
   * An optional function that allows a component to be used as the icon. Overrides `icon`.
   * USE SPARINGLY. THIS HAS FEW LEGITIMATE USES AND SHOULD ONLY BE USED WHEN `icon` IS INADEQUATE.
   * THIS IS ONLY IMPLEMENTED IN `IconRadioField`
   * @deprecated
   */
  iconResolver?: () => React.ReactElement;

  /**
   * Field watcher to decide whether the option is visible or not.
   */
  isVisible?: FieldWatcher<DataT, boolean>;

  /**
   * Whether the possible value is disabled or not.
   */
  isDisabled?: boolean;

  /**
   * Classname(s) to apply to option.
   */
  className?: string;
}

export type PossibleValueType<
  FieldVariantT extends FormFieldVariant,
  NameT extends FieldPath<DataT>,
  DataT extends FieldValues
> = Array<
  PossibleValue<
    FieldVariantT extends 'multiSelect'
      ? DataT[NameT] extends Array<infer TypeT>
        ? TypeT
        : never
      : DataT[NameT],
    DataT
  >
>;

export type CategorizedPossibleValueType<
  FieldVariantT extends FormFieldVariant,
  NameT extends FieldPath<DataT>,
  DataT extends FieldValues
> = Array<{
  category: string;
  categoryLabel?: string;
  values: PossibleValueType<FieldVariantT, NameT, DataT>;
}>;

/**
 * Form fields that has an enumeration of possible values should extend this interface. When used, the form will use
 * the key of the possible value as a pointer to the actual value. For cases when form field actually returns the key
 * of the possible value as the value of the field, the form will look up the value of the corresponding key and returns
 * the value for the key. This will be the case for most uncontrolled possible value fields. For controlled possible
 * value fields like DetailedSelectField, the actual value of the option can be set as the value of the field. The form
 * will return the actual value set as long as the value set is the value of one of the possible value source.
 */
export interface PossibleValuesField<
  NameT extends FieldPath<DataT>,
  DataT extends FieldValues,
  FieldVariantT extends FormFieldVariant
> extends FormFieldType<NameT, DataT, FieldVariantT> {
  /**
   * Possible values of the field.
   */
  values:
    | PossibleValueType<FieldVariantT, NameT, DataT>
    | CategorizedPossibleValueType<FieldVariantT, NameT, DataT>;
}

/**
 * Form field properties that defines how a field should be rendered. NameT is the name of the field and DataT is the
 * definition of bean that holds all the fields for the form.
 */
export type FormFieldProps<
  NameT extends FieldPath<DataT>,
  DataT extends FieldValues,
  TypeT extends FormFieldType<NameT, DataT, FormFieldVariant, ValueT>,
  ValueT = FieldPathValue<DataT, NameT>
> = {
  /**
   * Form field specification for the control that captures the value of the field.
   */
  fieldSpec: TypeT;

  /**
   * The id of the form.
   */
  formId: string;

  /**
   * The i18n of the form component. Used for getting localized text.
   */
  i18n: I18n<unknown, unknown>;

  /**
   * Adds specified classname to the form field.
   */
  className?: string;

  /**
   * All the fields specification in the form.
   */
  allFields: Array<FormFieldSpecDefinition<DataT>>;

  /**
   * React hook form form methods.
   */
  formMethods: UseFormReturn<DataT>;

  /**
   * form submit on change spec.
   */
  submitOnChange: DebounceProps<DataT>;

  /**
   * @callback called when submit on change is triggered.
   */
  onFormSubmit: () => void;

  /**
   * Minimum input length to start the search.
   */
  minimumInputLengthForSearch?: number;

  /**
   * Whether if the select is clearable or not.
   */
  isClearable?: boolean;
};

/**
 * Forms layout Section
 */

/**
 * Form field reference used in Form Layout
 */
export interface FormFieldRef {
  /**
   * Id of the field
   */
  id: string;
}

/**
 * Specifies common properties for form elements that render a group of fields together like Row, Col or Fieldset.
 * ItemT specifies the type of field items for the group. PropT specifies the properties applicable to the group.
 * FormGroupFieldT specifies the type of group.
 */
export interface FormGroupFieldTypeBehavior<
  ItemT,
  PropertyT,
  FormGroupFieldT extends FormGroupFieldType
> {
  /**
   * Id that uniquely identifies the group. The id needs to be unique throughout the form spec.
   */
  id: string;

  /**
   * The type of group element in the form.
   */
  type: FormGroupFieldT;

  /**
   * The properties for the group element in the form.
   */
  props?: PropertyT;

  /**
   * The element the group element may be rendered as.
   */
  as?: React.ElementType;

  /**
   * Adds the specified classname to the group element.
   */
  className?: string;

  /**
   * Specifies the collection of items in the group.
   */
  items: Array<ItemT>;

  /**
   * form group field view variant
   */
  viewVariant?: string;
}

/**
 * Form field column layout definition. Defines possible columns that can be used for the form layout.
 */
export type FormColumnDefinition = LayoutFormColumn | LayoutFormField;

/**
 * Form field row layout definition. Defines possible rows that can be used for the form row.
 */
export type FormRowDefinition =
  | LayoutFormRow
  | LayoutFormRowRow
  | LayoutFormFieldset
  | LayoutFormField;

/**
 * Form field label layout specification. Specifies how the form label should be rendered in the group.
 */
export interface FormLabel<AsT extends React.ElementType = 'label'> {
  /**
   * Element type to display the label as.
   */
  as?: AsT;

  /**
   * Specifies the props for the form label. All props specified at
   * {@link https://react-bootstrap.github.io/components/forms/#form-label-props} can be used here.
   */
  props?: FormLabelProps & AsT extends React.ElementType<infer PropsT> ? PropsT : never;

  /**
   * Adds the specified class name to the label of the field.
   */
  className?: string;
  /**
   * Renders custom content inside the <Form.Label>
   */
  labelTextOverride?: string | React.ReactNode;
}

/**
 * Specifies properties of legend html element.
 */
export interface Legend {
  /**
   * The classname to apply to the legend.
   */
  className?: string;
  /**
   * The element to render the legend as.
   */
  as?: React.ElementType;
  /**
   * Label override for the possible value.
   */
  label?: string;
  /**
   * The role to apply to the legend.
   */
  role?: string;
  /**
   * The aria-level to apply to the legend.
   */
  ariaLevel?: number;
}

/**
 * Specifies properties of description html element.
 */
export interface Description {
  /**
   * Classname to be applied for the description.
   */
  className?: string;

  /**
   * Label override for the description.
   */
  label?: string;
}

/**
 * Specifies properties of toggle button html element.
 */
export interface ToggleButtonProps {
  /**
   * Classname to be applied for the toggle button.
   */
  className?: string;
}

/**
 * Specifies properties of toggle collapse html element.
 */
export interface ToggleCollapseProps {
  /**
   * Classname to be applied for the toggle collapse.
   */
  className?: string;
}

/**
 * Form layout specification. Specifies how the form should render.
 */
export interface FormLayout {
  /**
   * Collection of form rows.
   */
  rows: Array<FormRowDefinition>;

  /**
   * Specify the position of action buttons in the form.
   */
  actionButtons?: FormActionButtonsLayout;

  /**
   * Adds the specified classname to the form.
   */
  className?: string;

  /**
   * Determines how to separate form groups.
   */
  formGroupFieldSeparator?: FormGroupFieldSeparator;
}

export interface DebounceProps<FormDataT extends FieldValues> {
  /**
   * The fields to watch to debounce submit. If this property is not specified, then submit happens as soon
   * as the form value changes for any field. If the field is specified as all, then the form will debounce submit for
   * any field change.
   */
  watchFields?: WatchFields<FormDataT>;
  /**
   * The wait time to debounce submit. If not specified defaults to 200ms.
   */
  waitTime?: number;
}

/**
 * Defines a form button which can be a loading button or standard button.
 */
export interface FormButton<FormDataT extends FieldValues>
  extends Omit<LoadingButtonProps, 'onClick'> {
  /**
   * Triggers when button is clicked on the form. the form data is passed in as the first argument and
   * the event as second.
   * @callback
   * @param data current state of data when button is clicked
   * @param event event
   * @param formState the form state
   */
  onClick?: (data: FormDataT, event: React.MouseEvent, formState: FormState<FormDataT>) => void;
  /**
   * Button type set to standard button.
   */
  buttonType?: LoadingButtonVariant;
}

/**
 * Form action button with the unique action id and its render props. FormDataT is the definition of bean that holds
 * all the fields for the form.
 */
export interface FormAction<FormDataT extends FieldValues> {
  /**
   * Action id for the action that triggers form submit.
   */
  actionId: string;
  /**
   * Form button specification for the action id.
   */
  buttonSpec?: FormButton<FormDataT>;
}

/**
 * Definition of form fields allowed for InputEditForm.
 */
export type FormFieldSpecDefinition<FormDataT extends FieldValues> =
  | FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant>
  | FormFieldGroupSpecDefinition<FormDataT>;

/**
 * Definition of form field group allowed for InputEditForm.
 */
export type FormFieldGroupSpecDefinition<FormDataT extends FieldValues> =
  | FormFieldsetType<FormDataT>
  | FormFieldRowSpec<FormDataT>
  | FormFieldFormRowSpec<FormDataT>
  | FormFieldColumnSpec<FormDataT>;

/**
 * Definition of items allowed for fieldset.
 */
export type FormFieldsetItem<FormDataT extends FieldValues> =
  | FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant>
  | FormFieldRowSpec<FormDataT>
  | FormFieldFormRowSpec<FormDataT>;

/**
 * Definition of items allowed for form Row.
 */
export type FormFieldRowItem<FormDataT extends FieldValues> =
  | FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant>
  | FormFieldsetType<FormDataT>
  | FormFieldColumnSpec<FormDataT>;

/**
 * Definition of items allowed for form column.
 */
export type FormFieldColumnItem<FormDataT extends FieldValues> =
  | FormFieldType<FieldPath<FormDataT>, FormDataT, FormFieldVariant>
  | FormFieldsetType<FormDataT>
  | FormFieldRowSpec<FormDataT>
  | FormFieldFormRowSpec<FormDataT>;

/**
 * Common specification for fieldset html element.
 */
export interface FormFieldsetCommonSpecs {
  /**
   * The legend specification for the fieldset.
   */
  legend?: Legend;

  /**
   * The description for the fieldset.
   */
  description?: Description;

  /**
   * If this property is specified, it will make the form fieldset toggleable when clicked on legend and the default
   * state will be the value provided for this property. Legend should be setup for this to work.
   */
  toggleState?: ToggleableState;

  /**
   * Classes applied to the fieldset
   */
  className?: string;

  /**
   * Settings applied to the toggle button
   */
  toggleButtonProps?: ToggleButtonProps;

  /**
   * Settings applied to the toggle collapse
   */
  toggleCollapseProps?: ToggleCollapseProps;
}

/**
 * Renders a row in layout form as Row bootstrap component. All props specified at
 * {@link https://react-bootstrap.github.io/layout/grid/#row-props} can be
 * specified as props.
 */
export type LayoutFormRow = FormGroupFieldTypeBehavior<
  FormColumnDefinition,
  RowProps,
  FormGroupFieldType.ROW
>;

/**
 * Renders a row in layout form as Form.Row bootstrap component.
 */
export type LayoutFormRowRow = FormGroupFieldTypeBehavior<
  FormColumnDefinition,
  undefined,
  FormGroupFieldType.FORM_ROW
>;

/**
 * Renders a column in layout form as a Col bootstrap component. All props specified at
 * {@link https://react-bootstrap.github.io/layout/grid/#col-props} can be specified as props.
 */
export type LayoutFormColumn = FormGroupFieldTypeBehavior<
  FormRowDefinition,
  ColProps,
  FormGroupFieldType.COL
>;

/**
 * Renders a row in InputEditForm as Row bootstrap component. All props specified at
 * {@link https://react-bootstrap.github.io/layout/grid/#row-props} can be
 * specified as props.
 */
export type FormFieldRowSpec<FormDataT extends FieldValues> = FormGroupFieldTypeBehavior<
  FormFieldRowItem<FormDataT>,
  RowProps,
  FormGroupFieldType.ROW
>;

/**
 * Renders a row in InputEditForm as Form.Row bootstrap component.
 */
export type FormFieldFormRowSpec<FormDataT extends FieldValues> = FormGroupFieldTypeBehavior<
  FormFieldRowItem<FormDataT>,
  undefined,
  FormGroupFieldType.FORM_ROW
>;

/**
 * Renders a column in InputEditForm as Col bootstrap component. All props specified at
 * {@link https://react-bootstrap.github.io/layout/grid/#col-props} can be specified as props.
 */
export type FormFieldColumnSpec<FormDataT extends FieldValues> = FormGroupFieldTypeBehavior<
  FormFieldColumnItem<FormDataT>,
  ColProps,
  FormGroupFieldType.COL
>;

/**
 * Renders a fieldset in LayoutForm.
 */
export type LayoutFormFieldset = FormGroupFieldTypeBehavior<
  LayoutFormRow | LayoutFormRowRow | LayoutFormField,
  FieldsetProps,
  FormGroupFieldType.FIELDSET
> &
  FormFieldsetCommonSpecs;

/**
 * Renders a fieldset in InputEditForm.
 */
export interface FormFieldsetType<FormDataT extends FieldValues>
  extends FormGroupFieldTypeBehavior<
      FormFieldsetItem<FormDataT>,
      FieldsetProps,
      FormGroupFieldType.FIELDSET
    >,
    FormFieldsetCommonSpecs {
  /*
   * Whether to use the passed id in the markup for the fieldset
   */
  useIDAsHtmlID?: boolean;
  /**
   * a DOM ref to the fieldset HTMLDivElement
   */
  ref?: React.RefObject<HTMLDivElement>;
}

/**
 * Specifies action buttons layout and positioning details.
 */
export interface FormActionButtonsLayout {
  /**
   * Specifies the position where action button bar should be rendered in the form.
   */
  actionButtonBarPosition?: FormActionButtonBarPosition;

  /**
   * Specifies the position where action buttons should be rendered in the form.
   */
  actionButtonsPosition?: FormActionButtonsPosition;

  /**
   * Classname(s) applied to the form actions container element. This element does not contain
   * the form action context component, only the form actions.
   */
  className?: string;

  /**
   * Classname(s) applied to the outer action container element. This contains the inner container.
   */
  outerContainerClassName?: string;

  /**
   * Classname(s) applied to the inner action container element. This contains both the
   * form action context component (if there is one) and the form actions.
   */
  innerContainerClassName?: string;
}

/**
 * Specifies details about form action buttons.
 */
export interface FormActions<FormDataT extends FieldValues> extends FormActionButtonsLayout {
  /**
   * Collection of form actions for the form. The actions will be rendered in the order it was inserted in the array.
   */
  formActions: Array<FormAction<FormDataT>>;
}

export interface FormOptions<FormDataT, FieldWatcherResponseT = void> {
  /**
   * className to apply to the form.
   */
  formClassName?: string;

  /**
   * className to apply to the div that wraps the form fields
   */
  fieldsWrapperClassName?: string;

  /**
   * ClassName to apply to the title.
   */
  formTitleClassName?: string;

  /**
   * Adds field watchers that will trigger the specified callback when
   * their value changes.
   */
  fieldWatchers?: FieldWatcher<FormDataT, FieldWatcherResponseT>;

  /**
   * Determines how each group in the form should be separated.
   */
  formGroupFieldSeparator?: FormGroupFieldSeparator;

  /**
   * Whether ReCaptcha should be used.
   */
  useReCaptcha?: boolean;

  /**
   * Classname to apply to the reCaptcha TOS.
   */
  reCaptchaClassName?: string;
}

export interface FormSchemaDefinition {
  schema: FormSchema;
  cx: ClassNamesFnWrapper;
}

/**
 * Form specification to build the InputEditForm. Specifies form fields, action buttons, id and i18n for the form.
 * FormDataT is the definition of bean that holds all the fields for the form.
 */
export interface FormSpec<FormDataT extends FieldValues> extends FormOptions<FormDataT> {
  /**
   * Id of the form.
   */
  formId: string;
  /**
   * i18n to use for the localized text.
   */
  i18n: I18n<unknown, unknown>;
  /**
   * Collection of form fields for the form. The fields will be rendered in the order it was inserted in the array.
   */
  formFields: Array<FormFieldSpecDefinition<FormDataT>>;
  /**
   * Specifies the collection of form actions to render for the form along with their positioning information.
   */
  formActionsSpec: FormActions<FormDataT>;
  /**
   * Any custom fields set for a form.
   */
  customProps?: Array<ComponentProp>;

  /**
   * If false retains the value of the form fields even when the field unmounts. {@see https://legacy.react-hook-form.com/api/useform}.
   * Defaults to true
   */
  shouldUnregister?: boolean;

  /**
   * This option allows you to configure when inputs with errors get re-validated after submit.
   * By default, validation is only triggered during an input change.
   */
  revalidateMode?: Exclude<Mode, 'onTouched' | 'all'>;

  /**
   * Specifies form fields and layout. Use this for complex form UI.
   */
  formSchema: FormSchemaDefinition;
}

/**
 * Specifies common properties of datetime editor components.
 */
export interface DatetimeEditor {
  /**
   * Enable or disable time picker.
   */
  useTimeSelect?: boolean;
  /**
   * Current value of the component.
   */
  value?: {
    dateTime: { startDate: Date; endDate: Date };
    timezone?: string;
  };
  /**
   * Minimum date allowed.
   */
  minDate?: Date;
  /**
   * Maximum date allowed.
   */
  maxDate?: Date;
  /**
   * Open the calendar on this date.
   */
  openToDate?: Date;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Event handler for date time selection
   */
  onChange?: (Date) => void;
  /**
   * To focus on the element.
   */
  focus?: boolean;
  /**
   * Function to apply focus on the element.
   */
  focusHandler?: ElementFocusHandler;
  /**
   * Form field ref.
   */
  hasBeenFocused?: React.MutableRefObject<boolean>;
  /**
   * Placeholder for input field.
   */
  placeholder?: string;
}

export type ControlledFieldChildrenProps<
  NameT extends FieldPath<FormDataT>,
  FormDataT extends FieldValues,
  TypeT extends FormFieldType<NameT, FormDataT, FormFieldVariant>
> = React.PropsWithChildren<{
  /**
   * render props for controlled field.
   */
  render: UseControllerReturn<FormDataT, NameT>;
  fieldSpec: TypeT;
  formProps: Omit<
    FormFieldProps<NameT, FormDataT, TypeT>,
    'submitOnChange' | 'onFormSubmit' | 'allFields' | 'className' | 'fieldSpec'
  >;
}>;
