import { useEffect } from 'react';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { handleClearCacheOnRouteChange } from '@aurora/shared-apollo/apolloCacheHelper';
import type { NextRouter } from 'next/dist/shared/lib/router/router';

/**
 * Clears the cache when the route changes if specified by a localStorage key.
 */
export default function useRouteChangeCacheClear(
  router: NextRouter,
  client: ApolloClient<NormalizedCacheObject>
): void {
  useEffect(() => {
    function handleRouteChange(url: string): void {
      handleClearCacheOnRouteChange(url, client);
    }
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [client, router.events]);
}
