import dynamic from 'next/dynamic';
import React from 'react';
import type IconTypes from '../../../public/static/graphics/processed/enums';

const PageErrorActual = dynamic(() => import('./PageErrorActual'));

interface Props {
  /**
   * The status code.
   */
  statusCode?: number;
  /**
   * The page text.
   */
  title?: string;
  /**
   * The icon for page error
   */
  icon?: IconTypes;
}

export type { Props };

/**
 * This "internal" component is intended to be used by the `PageError` component, and was separated out
 * to allow the `PageError` component to wrap all the logic in an `ErrorBoundary` component that can
 * fall back to a `StaticPageError` in the off chance the error that occurred is in the logic of this
 * component.
 */
const PageError: React.FC<React.PropsWithChildren<Props>> = ({
  statusCode = 404,
  title,
  icon
}): React.ReactElement => {
  return <PageErrorActual statusCode={statusCode} title={title} icon={icon} />;
};

export default PageError;
