import { EndUserPages } from '@aurora/shared-types/pages/enums';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import React, { useContext, useEffect } from 'react';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import SwitchBranchContext from '@aurora/shared-client/components/context/SwitchBranchContext/SwitchBranchContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import ThemeContext from '@aurora/shared-client/components/context/ThemeContext/ThemeContext';

/**
 * A wrapper component that scopes supplied css variable rules to its contents via middleware
 *
 * @author Dolan Halbrook
 */
const CachedThemeApplicator: React.FC<React.PropsWithChildren<{}>> = () => {
  const { themeInfo } = useContext(AppContext);
  const tenant = useContext(TenantContext);
  const { router } = useEndUserRoutes();
  const { branchName } = useContext(SwitchBranchContext);
  const finalBranchName = branchName ?? tenant.publicConfig.mainBranchName;
  const finalLastModified = themeInfo?.lastModified ?? '0';
  const { theme: contextTheme } = useContext(ThemeContext);
  const { id: themeId } = themeInfo;

  /**
   * LIA-87547 Prevent screen using default background color from being used
   * during navigation.
   */
  useEffect(() => {
    const currentPageName = router.getCurrentPageName();
    if (
      currentPageName !== EndUserPages.PageEditorPage &&
      currentPageName !== EndUserPages.ThemeEditorPage &&
      contextTheme?.colors?.bodyBg
    ) {
      document.body.style.backgroundColor = contextTheme.colors.bodyBg;
    }
  }, [contextTheme, router]);

  const styleUrl = UrlHelper.prefixBasePath(
    tenant,
    `/themes/style/${finalBranchName}/${themeId}-${finalLastModified}.css`
  );
  const fontUrl = UrlHelper.prefixBasePath(
    tenant,
    `/themes/font/${finalBranchName}/${themeId}-${finalLastModified}.css`
  );
  return (
    <>
      <link key="fontCss" rel="stylesheet" type="text/css" href={fontUrl} />
      <link key="themeCss" rel="stylesheet" type="text/css" href={styleUrl} />
    </>
  );
};

export default CachedThemeApplicator;
