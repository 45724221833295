import { getOperationName } from '@apollo/client/utilities';
import { filterNullInClauseVariables } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { useContext } from 'react';
import type { QueryResult, TypedDocumentNode } from '@apollo/client';
import { useApolloClient, useQuery } from '@apollo/client';
import type { OperationVariables } from '@apollo/client/core';
import type { DocumentNode } from 'graphql';
import type { QueryHookOptions } from '@apollo/client/react/types/types';
import type { ApolloQueryContext } from '@aurora/shared-types/apollo/tracing';
import useTracing from './useTracing';
import SwitchUserContext from './context/SwitchUserContext/SwitchUserContext';
import { getParentFrameId } from './context/AnalyticsParentFrames/useFrameEnd';
import { getCacheFetchPolicy } from '@aurora/shared-apollo/apolloCacheHelper';

export type ExtendedQueryResult<TData, TVariables> = QueryResult<TData, TVariables> & {
  /**
   * Ready signifies if data from the query is available.
   */
  ready: boolean;
};

/**
 * Wrapper around `useQuery` that adds tracking info to the `context` property.
 *
 * @param module the module of the component
 * @param query the query
 * @param options the query options
 * @param bypassSwitchUser whether or not to bypass switched user
 *
 * @author Adam Ayres
 */
export default function useQueryWithTracing<TData = unknown, TVariables = OperationVariables>(
  module: NodeModule | string,
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
  bypassSwitchUser?: boolean
): ExtendedQueryResult<TData, TVariables> {
  const operationName: string = getOperationName(query);
  const tracingContext: ApolloQueryContext = useTracing<
    TVariables,
    QueryHookOptions<TData, TVariables>
  >(module, operationName, options);
  const { switchUserId } = useContext(SwitchUserContext);
  const parentFrameId = getParentFrameId();
  const client = useApolloClient();

  const context: ApolloQueryContext = {
    ...tracingContext,
    parentFrameId,
    switchUserId: bypassSwitchUser ? undefined : switchUserId
  };
  if (options?.variables) {
    filterNullInClauseVariables(options.variables as Record<string, unknown>);
  }

  const fetchPolicy = getCacheFetchPolicy(query);
  const finalOptions: QueryHookOptions<TData, TVariables> = {
    fetchPolicy,
    ...options,
    context
  };

  const queryResult = useQuery<TData, TVariables>(query, finalOptions);
  const { data, loading, error } = queryResult;

  let finalLoading = loading;

  /**
   * LIA-97234 When using 'cache-and-network' Apollo sets the loading state to true even if the data is available
   * in the cache and during the network call to get fresh data. This ultimately will put the component into a
   * loading state even though the data is available in the cache. This is particularly problematic when
   * the component is a form (has state) and the state is lost when the component is unmounted and remounted.
   */
  if (finalOptions.fetchPolicy === 'cache-and-network') {
    const cachedValue = client.cache.readQuery({ query, variables: finalOptions?.variables });
    finalLoading = loading && !cachedValue;
  }

  return { ...queryResult, loading: finalLoading, ready: !error && !finalLoading && data != null };
}
