import { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { Feature } from './enums';
import type { CommunityFeature } from './index';
import { pageDescriptorUrlPath } from '@aurora/shared-client/routes/PageDescriptorRoute';
import { EntityType } from '@aurora/shared-types/nodes/enums';
import { endUserModuleRegistry } from './endUserModuleRegistry';
import { getEntityScopeForPage } from '@aurora/shared-client/helpers/routes/EndUserPageHelper';
import { noRedirectBehavior } from '@aurora/shared-types/redirects/pageRedirect';

export class SearchFeature implements CommunityFeature<Feature.SEARCH> {
  getFeature(): Feature.SEARCH {
    return Feature.SEARCH;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [
      new PageDescriptor(
        EndUserPages.SearchResultsPage,
        endUserModuleRegistry[EndUserComponent.SEARCH_RESULTS_PAGE],
        pageDescriptorUrlPath[EndUserPages.SearchResultsPage],
        getEntityScopeForPage(EntityType.COMMUNITY),
        noRedirectBehavior,
        true,
        null
      )
    ];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.SEARCH_WIDGET,
        component: dynamic(() => import('../components/search/SearchWidget/SearchWidget')),
        allowedPages: [EndUserPages.SearchResultsPage]
      },
      {
        id: EndUserComponent.SEARCH_MESSAGE_LIST_WIDGET,
        component: dynamic(
          () => import('../components/search/MessageListForSearchWidget/MessageListForSearchWidget')
        ),
        allowedPages: [EndUserPages.SearchResultsPage]
      },
      {
        id: EndUserComponent.SEARCH_USER_LIST_WIDGET,
        component: dynamic(
          () => import('../components/search/UserListForSearchWidget/UserListForSearchWidget')
        ),
        allowedPages: [EndUserPages.SearchResultsPage]
      },
      {
        id: EndUserComponent.SEARCH_NODE_LIST_WIDGET,
        component: dynamic(
          () => import('../components/search/NodeListForSearchWidget/NodeListForSearchWidget')
        ),
        allowedPages: [EndUserPages.SearchResultsPage]
      }
    ];
  }
}
